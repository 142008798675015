import { AbstractMesh, Mesh, ArcRotateCamera, Vector3, Sound,Scene,SceneLoader} from "babylonjs";
import {CharacterController} from "babylonjs-charactercontroller";
//import "@babylonjs/loaders/glTF";
import 'babylonjs-loaders';
import * as GUI from 'babylonjs-gui';

export class Character {
    private canvas: HTMLCanvasElement;
    public  camera: ArcRotateCamera;
    private ags : BABYLON.AnimationGroup[];
    private scene;

    public  player;
    public  agMap;
    public  cc;
    public playerNameText: GUI.TextBlock;
    private sessionid : string;
    private advancedTexture:GUI.AdvancedDynamicTexture;

    constructor(scene:Scene, canvas : HTMLCanvasElement, advancedTexutre:GUI.AdvancedDynamicTexture, sessionid : string) {
        this.scene = scene;
        this.canvas = canvas;

        this.sessionid = sessionid;
        this.advancedTexture = advancedTexutre;
    }

    public createCharacter(playerpath:string, modelfile:string){
        BABYLON.SceneLoader.ImportMesh("", playerpath, modelfile, this.scene, (meshes, particleSystems, skeletons,animationgroups) => {
            this.player = meshes[0];
            this.ags = animationgroups;

            this.initPlayer();
            this.initCharacterCamera();
            this.initCharacterController();
        },
        (progressevent) =>{
            console.log("load pregresss:" + progressevent.loaded + "/" + progressevent.total);
        },
        (scene,messages) => {
            console.error("load error:",messages);
        });
    }

    public async createCharacterAsync(playerpath:string, modelfile:string){
        const importPromise = await BABYLON.SceneLoader.ImportMeshAsync("", playerpath, modelfile, this.scene);
        if( importPromise )
        {
            this.player = importPromise.meshes[0];
            this.ags = importPromise.animationGroups;

            this.initPlayer();
            this.initAnimationGroup();
            this.initCharacterCamera();
            this.initCharacterController();

            this.initPlayerName();
        }
    }

    private initAnimationGroup(){
        for( let i = 0; i < this.ags.length; i++ ){
            this.ags[i].name += "+";
            this.ags[i].name += this.sessionid;

            this.ags[i].stop();
        }
        //映射动画；
        let agMap ={
            idle: this.ags.find(x => x.name == "idle+" + this.sessionid),
            strafeLeft: this.ags.find(x => x.name == "strafeLeft+" + this.sessionid),
            strafeRight: this.ags.find(x => x.name == "strafeRight+" + this.sessionid),
            turnRight: this.ags.find(x => x.name == "turnRight+" + this.sessionid),
            walk: this.ags.find(x => x.name == "walk+" + this.sessionid),
            fall: this.ags.find(x => x.name == "fall+" + this.sessionid),
            slideBack: this.ags.find(x => x.name == "slideBack+" + this.sessionid),
            runJump: this.ags.find(x => x.name == "runJump+" + this.sessionid),
            turnLeft: this.ags.find(x => x.name == "turnLeft+" + this.sessionid),
            walkBack: this.ags.find(x => x.name == "walkBack+" + this.sessionid),
            run: this.ags.find(x => x.name == "run+" + this.sessionid),
            idleJump: this.ags.find(x => x.name == "idleJump+" + this.sessionid),       
        }

        this.agMap = agMap;
    
    }

    private initPlayer(){
        //this.player.position = new BABYLON.Vector3(0, 12, 0);

        this.player.name = "root-player" + this.sessionid;
        //glb模型中的rotaion使用的是四元素表示,需简要转换为欧拉角
        this.player.rotation = this.player.rotationQuaternion.toEulerAngles();
        this.player.rotationQuaternion = null;
        this.player.rotation.y = Math.PI / 4;

        //允许碰撞检测，设置碰撞检测区域大小
        this.player.checkCollisions = true;
        this.player.ellipsoid = new BABYLON.Vector3(0.5, 1, 0.5);
        this.player.ellipsoidOffset = new BABYLON.Vector3(0, 1, 0);
    }

    private initPlayerName(){
        this.playerNameText = new GUI.TextBlock("playerNameText-" + this.sessionid);
        this.playerNameText.text = "玩家:" + this.sessionid;
        this.playerNameText.color = "white";

        // 设置文本块的样式
        this.playerNameText.fontSize = 24;
        this.playerNameText.outlineWidth = 2;
        this.playerNameText.outlineColor = "black";
        this.playerNameText.textHorizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
        this.playerNameText.textVerticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
/*
        const player = this.player;
        if( player ){
          const playerPosition = player.getAbsolutePosition();
          const playerDirection = player.getDirection(BABYLON.Vector3.Up());
          const textPosition = playerPosition.add(playerDirection.scale(1)); // 文本在玩家头顶上方2个单位的位置
    
          const viewportWidth = this.scene.getEngine().getRenderWidth();
          const viewportHeight = this.scene.getEngine().getRenderHeight();
          const viewport = {
            width:viewportWidth,
            height:viewportHeight,
            x:0,
            y:0
          }
    
          // 将文本的位置转换为屏幕坐标
          var screenPosition = BABYLON.Vector3.Project(textPosition, BABYLON.Matrix.Identity(), this.scene.getTransformMatrix(), <BABYLON.DeepImmutableObject<BABYLON.Viewport>>viewport);
    
          // 更新文本块的位置
          this.playerNameText.left = screenPosition.x;
          this.playerNameText.top = screenPosition.y;
*/
          this.advancedTexture.addControl(this.playerNameText);
        //}
    }

    private initCharacterCamera(){
        var alpha = (3 * Math.PI) / 2 - this.player.rotation.y;
        var beta = Math.PI / 2.5;
        var target = new Vector3(this.player.position.x, this.player.position.y + 1.5, this.player.position.z);
        this.camera = new ArcRotateCamera("camera-" + this.sessionid, alpha, beta, 5, target, this.scene);
        this.camera.attachControl(this.canvas, false);

        this.camera.keysLeft = [];
        this.camera.keysRight = [];
        this.camera.keysUp = [];
        this.camera.keysDown = [];
        this.camera.wheelPrecision = 15;
        this.camera.checkCollisions = false;

        this.camera.lowerRadiusLimit = 2;
        this.camera.upperRadiusLimit = 2.5;
        this.camera.upperBetaLimit = 2.13;
    }

    private initCharacterController()
    {
        this.cc = new CharacterController(this.player, this.camera, this.scene, this.agMap, true);                    
        this.cc.setMode(0);
        this.cc.setCameraTarget(new Vector3(0, 2, 0));
      
        this.cc.setNoFirstPerson(true);
        this.cc.setStepOffset(0.4);
        this.cc.setSlopeLimit(30, 60);
      
        this.cc.setIdleAnim(null, 1, true);
        this.cc.setTurnLeftAnim(null, 0.5, true);
        this.cc.setTurnRightAnim(null, 0.5, true);
        this.cc.setWalkAnim(this.agMap["walk2"], 1, true);
        this.cc.setWalkBackAnim(null, 0.5, true);
        this.cc.setIdleJumpAnim(null, 0.5, false);
        this.cc.setRunJumpAnim(null, 0.6, false);
        this.cc.setFallAnim(null, 2, false);
        this.cc.setSlideBackAnim(null, 1, false);

        this.cc.enableBlending(0.05);

        //if somehting comes between camera and avatar move camera in front of the obstruction?
        this.cc.setCameraElasticity(true);
        //if something comes between camera and avatar make the obstruction invisible?
        this.cc.makeObstructionInvisible(false);
        
        let sound = new Sound(
          "footstep",
          "./public/sounds/footstep_carpet_000.ogg",
          this.scene,
          () => {
            this.cc.setSound(sound);
          },
          { loop: false }
        );  

        this.cc.start();
    }
}
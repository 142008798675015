import * as BABYLON from 'babylonjs';
import * as GUI from 'babylonjs-gui';
import { Room } from "colyseus.js";

import Menu from "./menu";
import { createSkyBox, createSkyBox2 } from "./utils";
import { Character } from './character';
import 'babylonjs-loaders';

import {CharacterController} from "babylonjs-charactercontroller";

const GROUND_SIZE = 500;

export default class Game {
    private canvas: HTMLCanvasElement;
    private engine: BABYLON.Engine;
    private scene: BABYLON.Scene;
    private camera: BABYLON.ArcRotateCamera;
    private light: BABYLON.HemisphericLight;
    private shadowlight : BABYLON.DirectionalLight;

    private addPlayerTimes : number;
    private ccontroller: CharacterController;

    private room: Room<any>;
    private playerEntities: { [playerId: string]: BABYLON.Mesh } = {};
    private controllerEntities:{[playerId:string]: CharacterController} = {};
    private playerNextPosition: { [playerId: string]: BABYLON.Vector3 } = {};
    private playerNextRotY:{[playerId:string]: number} = {};
    private playerAction:{[playerId:string]: string} = {};
    private cameraEntities:{[playerId:string]: BABYLON.ArcRotateCamera} = {};
    
    private advancedTexture : GUI.AdvancedDynamicTexture;
    private playerNameTexts : { [playerId: string]: GUI.TextBlock } = {};

    constructor(canvas: HTMLCanvasElement, engine: BABYLON.Engine, room: Room<any>) {
        this.canvas = canvas;
        this.engine = engine;
        this.room = room;

        this.addPlayerTimes = 0;
        this.ccontroller = null;
    }

   initPlayers(): void {
        this.room.state.players.onAdd((player, sessionId) => {
            const isCurrentPlayer = (sessionId === this.room.sessionId);
            // Create player mesh
            const character = new Character(this.scene,this.canvas,this.advancedTexture,sessionId);
            character.createCharacterAsync("./public/player/","Vincent-frontFacing.glb").then(() => {
                const playerMesh = character.player;
                if( playerMesh )
                {
                    playerMesh.position.set(player.x, player.y, player.z);
                    playerMesh.rotation.set(0, player.ry, 0);
                
                    this.playerEntities[sessionId] = playerMesh;
                    this.playerNextPosition[sessionId] = playerMesh.position.clone();
                    this.playerNextRotY[sessionId] = playerMesh.rotation.y;

                    this.controllerEntities[sessionId] = character.cc;
                    this.cameraEntities[sessionId] = character.camera;

                    this.playerNameTexts[sessionId] = character.playerNameText;
                    this.playerAction[sessionId] = player.action;
                }

                player.onChange(() => {
                    this.playerNextPosition[sessionId]?.set(player.x, player.y, player.z);
                    this.playerNextRotY[sessionId] = player.ry;
                    this.playerAction[sessionId] = player.action;
                });

                //保证只进行一次设置.
                if( this.addPlayerTimes < 1){
                    this.engine.runRenderLoop(() => {
                        this.scene.render();
                    });
                }

                this.addPlayerTimes++;
            });
        });

        this.room.state.players.onRemove((player, playerId) => {
            this.playerEntities[playerId].dispose();
            delete this.playerEntities[playerId];
            delete this.playerNextPosition[playerId];
            delete this.playerNameTexts[playerId];
            delete this.cameraEntities[playerId];
            delete this.controllerEntities[playerId];
            delete this.playerNextRotY[playerId];
            delete this.playerAction[playerId];
        });

        this.room.onLeave(code => {
            this.gotoMenu();
        })
    }
    async createGround2(path:string, modelfile:string) {
        const importPromise = await BABYLON.SceneLoader.ImportMeshAsync("", path, modelfile, this.scene);
        if( importPromise )
        {
            const mesh = importPromise.meshes[0];
            mesh.name ="root-main";
            mesh.scaling = new BABYLON.Vector3(4.0, 4.0, -4.0);
            const meshes = mesh.getChildMeshes();
            meshes.forEach(m => {
                m.checkCollisions = true
            });

            this.initPlayers();
        }
    }

    createGround(): void {
        let groundMaterial = new BABYLON.StandardMaterial("groundMat", this.scene);
        let texture  = new BABYLON.Texture("./public/ground/ground.jpg", this.scene);
        texture.uScale = 4.0;
        texture.vScale = 4.0;
        groundMaterial.diffuseTexture = texture;
      
        let bumptexture  = new BABYLON.Texture("./public/ground/ground-normal.png", this.scene);
        bumptexture.uScale = 12.0;
        bumptexture.vScale = 12.0;
        groundMaterial.bumpTexture = bumptexture;

        groundMaterial.diffuseColor = new BABYLON.Color3(0.9, 0.6, 0.4);
        groundMaterial.specularColor = new BABYLON.Color3(0, 0, 0);

        BABYLON.MeshBuilder.CreateGroundFromHeightMap(
            "ground",
            "./public/ground/ground_heightMap.png",
            {
              width: 128,
              height: 128,
              minHeight: 0,
              maxHeight: 10,
              subdivisions: 32,
              onReady: (grnd) => {
                grnd.material = groundMaterial;
                grnd.checkCollisions = true;
                grnd.isPickable = true;
                grnd.freezeWorldMatrix();
              },
            },
            this.scene
        );
    }

    displayGameControls() {
        this.advancedTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI("textUI");

        const playerInfo = new GUI.TextBlock("playerInfo");
        playerInfo.text = `Room name: ${this.room.name}      Player: ${this.room.sessionId}`.toUpperCase();
        playerInfo.color = "#eaeaea";
        playerInfo.fontFamily = "Roboto";
        playerInfo.fontSize = 20;
        playerInfo.textHorizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
        playerInfo.textVerticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
        playerInfo.paddingTop = "10px";
        playerInfo.paddingLeft = "10px";
        playerInfo.outlineColor = "#000000";
        this.advancedTexture.addControl(playerInfo);

        const helpInfo = new GUI.TextBlock("helpInfo1");
        helpInfo.text = "Help:A(左转) D(右转) W(前进) S(后退) Space(跳跃)"; 
        helpInfo.color = "green";
        helpInfo.fontFamily = "Roboto";
        helpInfo.fontSize = 20;
        helpInfo.textHorizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
        helpInfo.textVerticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
        helpInfo.paddingTop = "10px";
        helpInfo.paddingLeft = "10px";
        helpInfo.top = "30px";
        helpInfo.outlineColor = "#000000";
        this.advancedTexture.addControl(helpInfo);

        
        const helpInfo2 = new GUI.TextBlock("helpInfo2");
        helpInfo2.text = "Help:E(右前方前进) Q(左前方前进) Shift+其他(加速)"; 
        helpInfo2.color = "green";
        helpInfo2.fontFamily = "Roboto";
        helpInfo2.fontSize = 20;
        helpInfo2.textHorizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
        helpInfo2.textVerticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
        helpInfo2.paddingTop = "10px";
        helpInfo2.paddingLeft = "10px";
        helpInfo2.top = "60px";
        helpInfo2.outlineColor = "#000000";
        this.advancedTexture.addControl(helpInfo2);

        // back to menu button
        const button = GUI.Button.CreateImageWithCenterTextButton("back", "<- BACK", "./public/btn-default.png");
        button.width = "100px";
        button.height = "50px";
        button.fontFamily = "Roboto";
        button.thickness = 0;
        button.color = "#f8f8f8";
        button.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT;
        button.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
        button.paddingTop = "10px";
        button.paddingRight = "10px";
        button.onPointerClickObservable.add(async () => {
            await this.room.leave(true);
        });
        button.zIndex = 1;
        this.advancedTexture.addControl(button);

        const debug = GUI.Button.CreateImageWithCenterTextButton("debug", "DEBUG", "./public/btn-default.png");
        debug.width = "100px";
        debug.height = "50px";
        debug.fontFamily = "Roboto";
        debug.thickness = 0;
        debug.color = "#f8f8f8";
        debug.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT;
        debug.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_BOTTOM;
        debug.paddingTop = "10px";
        debug.paddingRight = "10px";
        debug.onPointerClickObservable.add(async () => {
            console.log(this.scene.debugLayer.isVisible);
            this.scene.debugLayer.isVisible() ? this.scene.debugLayer.hide() : this.scene.debugLayer.show();                
        });
        debug.zIndex = 1;
        this.advancedTexture.addControl(debug);
    }

    caculateNamePosition(){
      // 计算文本的位置，这里我们假设文本应该在玩家头顶上方
      const player = this.playerEntities[this.room.sessionId];
      if( player ){
        let renderWidth = this.engine.getRenderWidth();
        let renderHeight = this.engine.getRenderHeight();
        console.log("width:" + renderWidth + ", height:" + renderHeight);
        let viewport = new BABYLON.Viewport(0,0,renderWidth, renderHeight);

        let playerWorldPosition = player.getAbsolutePosition();
        let viewportPosition = BABYLON.Vector3.Project(playerWorldPosition, BABYLON.Matrix.Identity(), this.scene.getTransformMatrix(), viewport);
        let screenPosition = new BABYLON.Vector2(viewportPosition.x + viewport.x, viewportPosition.y + viewport.y);

        console.log("screenPosition", screenPosition);
      }        
    }

    public listenKeyboard(){
        this.scene.actionManager = new BABYLON.ActionManager(this.scene);
        // 创建一个新的动作来响应按键事件
        var action = new BABYLON.ExecuteCodeAction(
            BABYLON.ActionManager.OnKeyDownTrigger,
            function (evt) {
                // 在这里处理按键事件
            console.log("Key pressed: " + evt.sourceEvent.key);
            }
        );
  
        // 将动作绑定到按键事件
        this.scene.actionManager.registerAction(action);
    }

    updateTargetPosition(): void {
        let shitPressed = false;
        this.scene.onKeyboardObservable.add((keybordinfo:BABYLON.KeyboardInfo) => {
            if (keybordinfo.type == BABYLON.KeyboardEventTypes.KEYDOWN) {                
                switch (keybordinfo.event.key) {
                    case "w":
                    case "W":
                    case "a":
                    case "A":
                    case "s":
                    case "S":
                    case "d":
                    case "D":
                    case "q":
                    case "Q":
                    case "e":
                    case "E":
                    case " ":
                        {
                            //当检测到这些按键按下后，控制玩家运动,同时发送位置更新                            
                            const targetPosition = this.playerEntities[this.room.sessionId]?.position.clone();
                            const targetRotation = this.playerEntities[this.room.sessionId]?.rotation.clone();
                            
                            let key = keybordinfo.event.key.valueOf();
                            if( shitPressed ){
                                key = "Shift" + key;
                            }
                            this.room.send("updatePosition", {
                                x: targetPosition.x,
                                y: targetPosition.y,
                                z: targetPosition.z,
                                ry: targetRotation.y,
                                action: key 
                            });
                            break;
                        }
                    case "Shift":
                        shitPressed = true;
                        break;
                    default:
                        break;
                }
            }

            if (keybordinfo.type == BABYLON.KeyboardEventTypes.KEYUP) {
                switch (keybordinfo.event.key) {
                    case "w":
                    case "W":
                    case "a":
                    case "A":
                    case "s":
                    case "S":
                    case "d":
                    case "D":
                    case "q":
                    case "Q":
                    case "e":
                    case "E":
                    case " ":
                        console.log("Key up:",this.playerAction);
                        const targetPosition = this.playerEntities[this.room.sessionId]?.position.clone();
                        const targetRotation = this.playerEntities[this.room.sessionId]?.rotation.clone();
                        
                        let key = keybordinfo.event.key.valueOf();
                        if( shitPressed ){
                            key = "Shift" + key;
                        }
                        this.room.send("updatePosition", {
                            x: targetPosition.x,
                            y: targetPosition.y,
                            z: targetPosition.z,
                            ry: targetRotation.y,
                            action: "stop"
                        });
                        break;
                    case "Shift":
                        shitPressed = false;
                        break;
                    default:
                        break;                        
                }
            }

        });
    }

    private initAxes(){
        const emptynode = new BABYLON.TransformNode("Axes", this.scene);
        const axes = new BABYLON.Debug.AxesViewer(this.scene, 0.5);
    }

    bootstrap(): void {
        this.scene = new BABYLON.Scene(this.engine);
        this.light = new BABYLON.HemisphericLight("HemisphericLight", new BABYLON.Vector3(0,1,0), this.scene);
        this.light.groundColor = new BABYLON.Color3(0.945,0.773,0.435);
        this.initAxes();

        createSkyBox2(this.scene);

        //this.createGround();
        this.createGround2("public/ground/", "zhxy.glb");
        this.displayGameControls();
        this.updateTargetPosition();
        this.initShadow();
        this.doRender();
    }

    // 模拟按键事件的函数
    public simulateKeyEvent(key)
    {
        const event = new KeyboardEvent("keydown", { key: key });
        document.dispatchEvent(event);
    }

    private initShadow()
    {
        this.shadowlight = new BABYLON.DirectionalLight("directionLight", new BABYLON.Vector3(0, -2, 3), this.scene);
        this.shadowlight.position = new BABYLON.Vector3(0, 35, -200);
        this.shadowlight.shadowEnabled = true;

        const shadowGenerator = new BABYLON.ShadowGenerator(1024,this.shadowlight);
        //shadowGenerator.useBlurCloseExponentialShadowMap = true;
        //shadowGenerator.useKernelBlur = true;

        //PCSS阴影，效果会更柔和，但是处理器成本更高;
        shadowGenerator.useContactHardeningShadow = true;
        shadowGenerator.contactHardeningLightSizeUVRatio = 0.05;
        shadowGenerator.filteringQuality= BABYLON.ShadowGenerator.QUALITY_LOW;

        this.scene.onReadyObservable.add(() => {
            this.scene.meshes.forEach((mesh) => {
                if( mesh.name.includes("X_Dixing") == false )
                {
                    if( mesh.name.includes("skyBox") == false)
                    {
                        shadowGenerator.addShadowCaster(mesh);
                    }                    
                }
                else
                {
                    mesh.receiveShadows = true;
                }
            });
        });
    }

    private gotoMenu() {
        this.scene.dispose();
        const menu = new Menu('renderCanvas');
        menu.createMenu();
    }

    private doKeyAction()
    {
        for (let sessionId in this.controllerEntities) {
            const cc = this.controllerEntities[sessionId];
            if( this.room.sessionId == sessionId )
            {                    
                cc.enableKeyBoard(true);
            }
            else
            {
                cc.enableKeyBoard(false);
            }

            const camera = this.cameraEntities[sessionId];
            if( this.room.sessionId == sessionId)
            {
                this.scene.activeCamera = camera;
            }

            if( this.room.sessionId != sessionId )
            {
                let action = this.playerAction[sessionId];
                if( action == "w")
                {
                    cc.walk(true);
                }
                else if( action == "s")
                {
                    cc.walkBack(true);
                }
                else if( action == "a")
                {
                    cc.turnLeft(true);
                }
                else if( action == "d")
                {
                    cc.turnRight(true);
                }
                else if( action == "q")
                {
                    cc.strafeLeft(true);
                }
                else if( action == "e")
                {
                    cc.strafeRight(true);
                }
                else if( action == " ")
                {
                    cc.jump();
                }
                else if( action == "ShiftW")
                {
                    cc.run(true);
                }
                else if( action == "ShiftS")
                {
                    cc.walkBackFast(true);
                }
                else if( action =="ShiftA")
                {
                    cc.turnLeftFast(true);
                }
                else if( action =="ShiftD")
                {
                    cc.turnRightFast(true);
                }
                else if( action == "ShiftQ")
                {
                    cc.strafeLeftFast(true);
                }
                else if( action == "ShiftE")
                {
                    cc.strafeRightFast(true);
                }
                else if(action == "stop")
                {
                    cc.idle(); 
                    this.playerAction[sessionId] = "";                       
                }
            }
        }
    }

    private doRender(): void {
        // constantly lerp players
        
        this.scene.registerBeforeRender(() => {
            
            for( let sessionId in this.playerEntities)
            {
                //更改玩家的位置;
                let playerEntiy = this.playerEntities[sessionId];
                let targetPosition = this.playerNextPosition[sessionId];
                playerEntiy.position = targetPosition;
                playerEntiy.rotation.y = this.playerNextRotY[sessionId];

                //计算玩家名称的位置；                
                let viewport = new BABYLON.Viewport(0,0,this.engine.getRenderWidth(), this.engine.getRenderHeight());
                let playerWorldPosition = playerEntiy.getAbsolutePosition().clone();
                playerWorldPosition.y = playerWorldPosition.y + 1.5;
                let viewportPosition = BABYLON.Vector3.Project(playerWorldPosition, BABYLON.Matrix.Identity(), this.scene.getTransformMatrix(), viewport);
                let screenPosition = new BABYLON.Vector2(viewportPosition.x + viewport.x, viewportPosition.y + viewport.y);
                screenPosition.y = Math.max(0,Math.min(screenPosition.y, viewport.height));               
        
                let nameTextBlock = this.playerNameTexts[sessionId];
                nameTextBlock.left = screenPosition.x;
                nameTextBlock.top = screenPosition.y;

            }

            this.doKeyAction();
        });

        // The canvas/window resize event handler.
        window.addEventListener('resize', () => {
            this.engine.resize();
        });
    }
}
